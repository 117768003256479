import React from "react";
import { useSpring, animated, config } from "react-spring";

import computerStyles from "./computer.module.scss";


import computerGif from "../../../images/home-computer-reduced2.gif";

const Computer = ({
  showLeftScreen,
  showRightScreen,
  link,
  className,
  onClick
}) => {
  showLeftScreen = showLeftScreen === undefined ? true : showLeftScreen;
  showRightScreen = showRightScreen === undefined ? true : showRightScreen;

  const leftBox = useSpring({
    from: {
      opacity: 0,
      top: 122
    },
    to: {
      opacity: showRightScreen && !showLeftScreen ? 1 : 0,
      top: showLeftScreen && !showRightScreen ? 82 : 122
    },
    config: config.gentle
  });

  const rightBox = useSpring({
    from: {
      opacity: 0,
      top: 122
    },
    to: {
      opacity: showLeftScreen && !showRightScreen ? 1 : 0,
      top: showLeftScreen && !showRightScreen ? 82 : 122
    },
    config: config.gentle
  });


  return (
    <div className={`${computerStyles.wrap} ${className}`} onClick={onClick}>
      <img src={computerGif} style={{ width: "100%" }} />

      <animated.div
        style={rightBox}
        className={`${computerStyles.rightBox}`}
      >
        This RPA bot is using a web browser, spreadsheet, and command
        prompt.
          </animated.div>
      <animated.div style={leftBox} className={`${computerStyles.leftBox}`}>
        We will program your amazing bot.
          </animated.div>
      {link}
    </div>
  );
};

export default Computer;
