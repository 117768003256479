import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
// import scrollTo from "gatsby-plugin-smoothscroll";
import { LinkRenderer } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import Hero from "../../components/panels/Hero";
import Scrollspy from "../../components/panels/Scrollspy";
import MoreCards from "../../components/panels/MoreCards";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
import ListItem from "../../components/panels/ListItem";
import PanelFoundation from "../../components/panels/PanelFoundation";
import Approach from "../../components/panels/Approach";
import SEO from "../../components/seo";

//slides
import YourCompanyStar from "../../components/non-panels/animations/yourCompanyStar";
import TalkingHeads from "../../components/non-panels/animations/talkingHeads";
import Computer from "../../components/non-panels/animations/computer";

//styles
import s from "./rpa.module.scss";

// images
import RPAApproachImage from "../../images/Approach/RPA_approach.jpg";
import RPAApproachImageSmall from "../../images/Approach/RPA_approach_800x500.jpg";

const RPA = ({ data, location }) => {
  const {
    SEO: seo,
    hero,
    scroll_view,
    cognitive_assistants,
    raas_overview,
    raas_cards,
    coe,
    audit,
    document_understanding_overview,
    document_understanding_cards,
    audit_overview
  } = data.strapiPsRpa;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `RPA Solution Providers and Consultants for RPA Implementation | Solutions`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-rpa.mp4"}
      />
      <Scrollspy
        className={`${s.paddingTop}`}
        slides={{
          one: <YourCompanyStar />,
          two: <TalkingHeads />,
          three: (
            <div className={s.computerWrapper}>
              <Computer showLeftScreen={false} />
            </div>
          ),
          four: (
            <div className={s.computerWrapper}>
              <Computer showRightScreen={false} />
            </div>
          ),
          five: (
            <div className={s.computerWrapper}>
              <Computer />
            </div>
          )
        }}
        content={scroll_view.map((sw, i) => {
          let id;

          switch (i) {
            case 0:
              id = "one";
              break;
            case 1:
              id = "two";
              break;
            case 2:
              id = "three";
              break;
            case 3:
              id = "four";
              break;
            case 4:
              id = "five";
              break;
          }

          return (
            <section id={id} key={i} className={`text-justify ${s.section}`}>
              <h2>{sw.heading}</h2>
              <hr />
              <ReactMarkdown
                source={sw.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            </section>
          );
        })}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"RPA as a Service"}
        id={"rpa-raas"}
      />

      <PanelFoundation rootClassName={`${s.saas} pb-0`}>
        <div className={`col-sm-12 text-justify`}>
          <ReactMarkdown
            source={raas_overview}
            renderers={{ link: LinkRenderer }}
          />
        </div>
      </PanelFoundation>
      {/* <div
        className={`${layoutStyle.containerFluid} ${s.saas} ${layoutStyle.pb0}`}
      >
        <div className={`${layoutStyle.container}`}>
          <div className={` ${layoutStyle.row}`}>
            <div
              className={`${layoutStyle.colSm12} ${layoutStyle.textJustify}`}
            >
              <ReactMarkdown source={raas_overview} />
            </div>
          </div>
        </div>
      </div> */}
      <ListItem
        columnsPerRow={3}
        isCard
        // hasCircle
        // circleColor={"white"}
        // circleBorderColor={"var(--primary)"}
        rootClassName={s.saas}
        itemClassName={`${s.card}`}
        contents={raas_cards.map((oc, i) => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: (
            <ReactMarkdown
              source={oc.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />
      <ComponentHeader
        bgColor={"white"}
        textColor={"purple"}
        text={"RPA Center of Excellence"}
        id={"rpa-coe"}
      />
      <SwitchComponent
        contents={coe.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: (
              <ReactMarkdown
                source={cog.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          };
        })}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Health Assessments"}
        id={"rpa-audit"}
      />

      <PanelFoundation rootClassName={`${s.saas} pb-0`}>
        <div className={`col-sm-12 text-justify`}>
          <ReactMarkdown
            source={audit_overview}
            escapeHtml={false}
            renderers={{ link: LinkRenderer }}
          />
        </div>
      </PanelFoundation>
      {/* <div className={`${layoutStyle.containerFluid} ${s.saas}`}>
        <div className={`${layoutStyle.container}`}>
          <div className={` ${layoutStyle.row}`}>
            <div
              className={`${layoutStyle.colSm12} ${layoutStyle.textJustify}`}
            >
              <ReactMarkdown source={audit_overview} escapeHtml={false} />
            </div>
          </div>
        </div>
      </div> */}
      <ListItem
        columnsPerRow={2}
        isCard
        // hasCircle
        // circleColor={"white"}
        // circleBorderColor={"var(--purple)"}
        rootClassName={s.saas}
        itemClassName={`${s.card}`}
        contents={audit.map(oc => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: (
            <ReactMarkdown
              source={oc.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Our Approach"}
      />
      {/* <Approach /> */}
      <div className={`${s.ApproachImageContainer}`}>
        <img className={`${s.ApproachImage}`} />
      </div>
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"More Solutions"}
      />
      <MoreCards
        rootClassName={`bg-light`}
        itemClassName={`col-lg-4 col-md-6 `}
        cards={["ArtificialIntelligence", "IntelligentAutomation", "Chatbots"]}
      />
    </>
  );
};

export default RPA;

export const pageQuery = graphql`
  {
    strapiPsRpa {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      scroll_view {
        id
        heading
        content
      }

      raas_overview

      raas_cards {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }

      coe {
        heading
        content
        row_switch_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      audit_overview
      audit {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
