import React, { useState, useRef, useEffect } from 'react'
import Scrollspy from 'react-scrollspy'

import useWindowSize from "../../utils/useWindowSize"

// style
import scrollspyStyle from "./Scrollspy.module.scss";

const ScrollSpy = ({slides, content, className}) => {

    // window goes in ref, and have a default.
    let size;
    let [innerWidth, innerHeight] = (size = useWindowSize());

    const [currentSlide, setSlide] = useState(<></>);

    const renderSlide = id => {
        setSlide(slides[id])
    };

    const ScrollspyOnUpdateHandler = html => {
        if (!html) {
            return;
        }
        renderSlide(html.id);
    }

    return (
        <div className={`container-fluid ${className}`}>
            <div className={`container`}>
                <div className={`row`}>
                    <div
                        className={`col-lg-6 col-md-12 ${scrollspyStyle.presentation}`}
                    >
                        <Scrollspy
                            offset={
                                size ? (innerWidth < 680 ? (innerHeight - 265) * -1 : (innerWidth < 993 ? (innerHeight - 305) * -1 :-250)) : 0 // set to 0 as default.
                            }
                            items={[
                                "one",
                                "two",
                                "three",
                                "four",
                                "five",
                                "six",
                                "seven",
                                "eight",
                            ]}
                            onUpdate={ScrollspyOnUpdateHandler}
                        >
                            <li>{currentSlide}</li>
                        </Scrollspy>
                    </div>
                    <div className={`col-lg-6 col-md-12 ${scrollspyStyle.lowerZIndex}`}>
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScrollSpy;