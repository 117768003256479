import React from "react";
import { useSpring, animated, config } from "react-spring";

import financeIcon from '../../../images/icons_new/finances_1.png';
import hrIcon from '../../../images/icons_new/hr_1.png';
import legalIcon from '../../../images/icons_new/legal_1.png';
import salesIcon from '../../../images/icons_new/sales_1.png';
import warehouseIcon from '../../../images/icons_new/warehousing_1.png'

import YourCompanyStarStyles from "./yourCompanyStar.module.scss";

const YourCompanyStar = () => {
  const delay = {
    ourCompanyCircle: 0,
    ourCompanyText: 350,
    line1: 250,
    legalCircle: 350,
    legalText: 450,

    line2: 350,
    hrCircle: 450,
    hrText: 550,

    line3: 650,
    financeCircle: 750,
    financeText: 850,

    line4: 550,
    salesCircle: 650,
    salesText: 750,

    line5: 450,
    warehousingCircle: 550,
    warehousingText: 650
  };

  const ourCompanyCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "100px", height: "100px" },
    delay: delay.circleOurCompany,
    config: config.wobbly
  });

  const ourCompanyText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.ourCompanyText,
    config: config.wobbly
  });

  const line1 = useSpring({
    from: { width: "0px" },
    to: { width: "40px" },
    delay: delay.line1,
    config: config.wobbly
  });

  const legalCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "50px", height: "50px" },
    delay: delay.legalCircle,
    config: config.wobbly
  });

  const legalText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.legalText
  });

  const line2 = useSpring({
    from: { width: "0px" },
    to: { width: "40px" },
    delay: delay.line2,
    config: config.wobbly
  });

  const hrCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "50px", height: "50px" },
    delay: delay.hrCircle,
    config: config.wobbly
  });

  const hrText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.hrText
  });

  const line3 = useSpring({
    from: { width: "0px" },
    to: { width: "40px" },
    delay: delay.line3,
    config: config.wobbly
  });

  const financeCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "50px", height: "50px" },
    delay: delay.financeCircle,
    config: config.wobbly
  });

  const financeText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.financeText
  });

  const line4 = useSpring({
    from: { width: "0px" },
    to: { width: "40px" },
    delay: delay.line4,
    config: config.wobbly
  });

  const salesCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "50px", height: "50px" },
    delay: delay.salesCircle,
    config: config.wobbly
  });

  const salesText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.salesText
  });

  const line5 = useSpring({
    from: { width: "0px" },
    to: { width: "40px" },
    delay: delay.line5,
    config: config.wobbly
  });

  const warehousingCircle = useSpring({
    from: { width: "0px", height: "0px" },
    to: { width: "50px", height: "50px" },
    delay: delay.warehousingCircle,
    config: config.wobbly
  });

  const warehousingText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.warehousingText
  });

  return (
    
// import financeIcon from '../../../images/icons_new/finances_1.png';
// import hrIcon from '../../../image/icons_new/hr_1.png';
// import legalIcon from '../../../images/icons_new/legal_1.png';
// import salesIcon from '../../../images/icons_new/sales_1.png';
// import warehouseIcon from '../../../images/icons_new/warehousing_1.png'

    <>
      <animated.div
        style={ourCompanyCircle}
        className={YourCompanyStarStyles.ourCompanyCircle}
      >
        <animated.div style={ourCompanyText}>Your Organization</animated.div>
      </animated.div>
      <animated.div
        style={line1}
        className={YourCompanyStarStyles.line1}
      ></animated.div>
      <animated.div
        style={legalCircle}
        className={YourCompanyStarStyles.legalCircle}
      >
        <img src={legalIcon} style={{width: "100%"}} />
        {/* <i className="fas fa-gavel fa-2x"></i> */}
      </animated.div>
      <animated.div
        style={legalText}
        className={YourCompanyStarStyles.legalText}
      >
        Legal
      </animated.div>

      <animated.div
        style={line2}
        className={YourCompanyStarStyles.line2}
      ></animated.div>
      <animated.div style={hrCircle} className={YourCompanyStarStyles.hrCircle}>
        {/* <i className="fas fa-user-tie fa-2x"></i> */}
        <img src={hrIcon} style={{width: "100%"}} />
      </animated.div>
      <animated.div style={hrText} className={YourCompanyStarStyles.hrText}>
        HR
      </animated.div>

      <animated.div
        style={line3}
        className={YourCompanyStarStyles.line3}
      ></animated.div>
      <animated.div
        style={financeCircle}
        className={YourCompanyStarStyles.financeCircle}
      >
      <img src={financeIcon} style={{width: "100%"}} />
        {/* <i className="fas fa-receipt fa-2x"></i> */}
      </animated.div>
      <animated.div
        style={financeText}
        className={YourCompanyStarStyles.financeText}
      >
        Finances
      </animated.div>

      <animated.div
        style={line4}
        className={YourCompanyStarStyles.line4}
      ></animated.div>
      <animated.div
        style={salesCircle}
        className={YourCompanyStarStyles.salesCircle}
      >
      <img src={salesIcon} style={{width: "100%"}} />
        {/* <i className="fas fa-dollar-sign fa-2x"></i> */}
      </animated.div>
      <animated.div
        style={salesText}
        className={YourCompanyStarStyles.salesText}
      >
        Sales
      </animated.div>

      <animated.div
        style={line5}
        className={YourCompanyStarStyles.line5}
      ></animated.div>
      <animated.div
        style={warehousingCircle}
        className={YourCompanyStarStyles.warehousingCircle}
      >
      <img src={warehouseIcon} style={{width: "100%"}} />
        {/* <i className="fas fa-industry fa-2x"></i> */}
      </animated.div>
      <animated.div
        style={warehousingText}
        className={YourCompanyStarStyles.warehousingText}
      >
        Warehousing
      </animated.div>
    </>
  );
};

export default YourCompanyStar;
