import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";
import { useSpring, animated, config } from "react-spring";

import talkingHeadStyles from "./talkingHeads.module.scss";

export default props => {
  const delay = {
    person1: 250,
    person2: 350,
    person3: 450,
    person4: 550,
    person5: 650,
    person6: 750
  };

  const person1 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person1,
    config: config.gentle
  });

  const person2 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person2,
    config: config.gentle
  });

  const person3 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person3,
    config: config.gentle
  });

  const person4 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person4,
    config: config.gentle
  });

  const person5 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person5,
    config: config.gentle
  });

  const person6 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: delay.person6,
    config: config.gentle
  });

  const chatBubble1 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 160,
      tension: 170
    }
  });

  const chatBubble2 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 80,
      tension: 170
    }
  });

  const chatBubble3 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 60,
      tension: 170
    }
  });

  const chatBubble4 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 400 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 120,
      tension: 170
    }
  });

  const chatBubble5 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 400 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 260,
      tension: 170
    }
  });

  const chatBubble6 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 400 + Math.floor(Math.random() * 1000) + 100,
    config: {
      friction: 0,
      mass: 60,
      tension: 170
    }
  });
  return (
    <StaticQuery
      query={graphql`
        query {
          person1: file(relativePath: { eq: "icons_new/character_06.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          person2: file(relativePath: { eq: "icons_new/character_02a.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          person3: file(relativePath: { eq: "icons_new/character_03.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          person4: file(relativePath: { eq: "icons_new/character_04a.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          person5: file(relativePath: { eq: "icons_new/character_01.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          person6: file(relativePath: { eq: "icons_new/character_06a.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          chatBubble: file(relativePath: { eq: "people/chat-bubble.png" }) {
            childImageSharp {
              fluid(maxWidth: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className={talkingHeadStyles.wrapper}>
            <div className={talkingHeadStyles.row}>
              <animated.div
                style={person1}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person1.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble1}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
              <animated.div
                style={person2}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person2.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble2}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
              <animated.div
                style={person3}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person3.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble3}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
            </div>
            <div className={talkingHeadStyles.row}>
              <animated.div
                style={person4}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person4.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble4}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
              <animated.div
                style={person5}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person5.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble5}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
              <animated.div
                style={person6}
                className={talkingHeadStyles.personWrapper}
              >
                <Img
                  className={talkingHeadStyles.person}
                  fluid={data.person6.childImageSharp.fluid}
                  alt="Person Icon"
                />
                <animated.div
                  style={chatBubble6}
                  className={talkingHeadStyles.bubbleWrapper}
                >
                  <Img
                    fluid={data.chatBubble.childImageSharp.fluid}
                    alt="Chat Bubble"
                  />
                </animated.div>
              </animated.div>
            </div>
          </div>
        );
      }}
    />
  );
};
